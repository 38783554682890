import { AdminTransaction } from "../../pages/TransactionDetails/types";
import { Tabs } from "./types";
import {
  EstateAgentTab,
  PropertyTab,
  ThirdfortTab,
  SellersTab,
  PurchaseIntentTab,
  OrdersTab,
  SolicitorTab,
} from ".";
import { SearchesTab } from "./SearchesTab";
import { EventsTimelineTab } from "./EventsTimelineTab";

interface RenderTabContentProps {
  currentTab: Tabs;
  transaction: AdminTransaction;
  onTransactionUpdated: (transaction: AdminTransaction) => void;
}

export const RenderTabContent = ({
  currentTab,
  transaction,
  onTransactionUpdated,
}: RenderTabContentProps): React.ReactNode => {
  switch (currentTab) {
    case "property":
      return (
        <PropertyTab
          address={transaction.property_id.address}
          hasMortgage={transaction.has_mortgage}
          onwardPurchase={transaction.onward_purchase}
        />
      );
    case "thirdfort":
      return <ThirdfortTab transaction={transaction} />;
    case "sellers":
      return <SellersTab transaction={transaction} />;
    case "purchaseIntents":
      return (
        <div>
          {transaction.purchase_intent.map((purchaseIntent, index) => (
            <PurchaseIntentTab
              key={`purchase-intent-${index}`}
              purchaseIntentIndex={index + 1}
              purchaseIntent={purchaseIntent}
              onUpdatedPurchaseIntent={(newPurchaseIntent) => {
                const updatedPurchaseIntents = transaction.purchase_intent.map(
                  (pi, i) => (i === index ? newPurchaseIntent : pi),
                );

                onTransactionUpdated({
                  ...transaction,
                  purchase_intent: updatedPurchaseIntents,
                });
              }}
            />
          ))}
        </div>
      );
    case "orders":
      return <OrdersTab orders={transaction.orders} />;
    case "solicitors":
      return (
        <SolicitorTab
          lawyerGroup={transaction.lawyer_group}
          transaction={transaction}
          setTransaction={onTransactionUpdated}
        />
      );
    case "estateAgent":
      return (
        <EstateAgentTab
          transactionId={transaction._id}
          estateAgent={transaction.estate_agent}
          setTransaction={onTransactionUpdated}
        />
      );
    case "searches":
      return (
        <SearchesTab
          propertyAddress={transaction.property_id.address}
          transactionId={transaction._id}
          searches={transaction?.searches}
          onSearchesOrdered={onTransactionUpdated}
          lawyerGroupPresentOnTransaction={
            !!transaction.lawyer_group?.seller &&
            !!transaction.lawyer_group.buyer
          }
        />
      );
    case "events":
      return <EventsTimelineTab transaction={transaction} />;
  }
};
