import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import Typography from "@/foundation/Typography/Typography";
import Icons from "@/foundation/Icons/Icons";
import { capitalizeFirstLetter } from "@shared/utils";

import { OrderSearchModal } from "../Modals/OrderSearchModal";
import { Address } from "../../pages/UserDetails/types";
import { AdminTransaction } from "../../pages/TransactionDetails/types";
import { ApiController } from "../../../service/ApiController";
import { format } from "date-fns";

export interface SearchesTabProps {
  propertyAddress: Address;
  transactionId: string;
  searches: AdminTransaction["searches"];
  onSearchesOrdered: (transaction: AdminTransaction) => void;
  lawyerGroupPresentOnTransaction?: boolean;
}

const searchProductMapping: { [key: string]: string } = {
  LLC1CON29: "Local",
  ONESEARCHDW: "Drainage & Water",
  ENVRES: "Environmental",
  LANDREGPLAN: "Land Registry Plan",
};

export const SearchesTab = ({
  propertyAddress,
  transactionId,
  lawyerGroupPresentOnTransaction,
  searches,
  onSearchesOrdered,
}: SearchesTabProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransactionPaid, setIsTransactionPaid] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchEvents() {
      setLoading(true);
      try {
        const transactionEvents =
          await ApiController.fetchTransactionEvent(transactionId);

        setIsTransactionPaid(
          transactionEvents.some(
            (event) => event.event_name === "payment_successful",
          ),
        );
      } catch (error) {
        console.log(JSON.stringify(error));
      }

      setLoading(false);
    }

    fetchEvents();
  }, [transactionId]);

  return (
    <>
      <Card
        title="Searches"
        titleBackgroundColor="brand-heavy-warmth-25"
        titleColor="brand-heavy-teal-100"
        titleRightContent={
          searches && (
            <Link
              to={`https://onesearch.direct/previous_orders/order/${searches?.reference}/`}
              className="flex"
              target="_blank"
            >
              <Typography className="mr-[8px]">View</Typography>
              <div className="mt-[1.5px]">
                <Icons iconName="NewWindow" />
              </div>
            </Link>
          )
        }
      >
        {searches ? (
          <div>
            {searches.ordered.map((search) => (
              <div
                key={search.productId}
                className="mb-[28px] flex items-start"
              >
                <div>
                  <Typography
                    variant="h4"
                    weight="bold"
                    className="text-brand-heavy-teal-100"
                  >
                    {`${searchProductMapping[search.productId]} (${search.productId})`}
                  </Typography>

                  <div className="flex mt-[8px] items-center">
                    <Typography
                      weight="bold"
                      variant="lg"
                      className={
                        search.status === "in progress"
                          ? "text-ui-warning-100"
                          : "text-ui-success-100"
                      }
                    >
                      {capitalizeFirstLetter(search.status)}
                    </Typography>
                    <Typography
                      weight="semi-bold"
                      variant="lg"
                      className="mx-[8px] text-brand-heavy-teal-100"
                    >
                      |
                    </Typography>
                    <Typography
                      weight="semi-bold"
                      variant="lg"
                      className="text-brand-heavy-teal-100"
                    >
                      {search.status === "in progress"
                        ? `${search.estimatedCompletion ? `${format(new Date(search.estimatedCompletion), "dd/MM/yyyy")} (est)` : "estimation date unknown"}`
                        : `${search.completionDate ? format(new Date(search.completionDate), "dd/MM/yyyy") : "completion date unknown"}`}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <Typography variant="lg" weight="semi-bold" className="mb-[16px]">
              No searches have been ordered yet.
            </Typography>

            <Button
              variant="primary"
              onClick={() => setIsModalOpen(true)}
              disabled={
                loading ||
                !lawyerGroupPresentOnTransaction ||
                !isTransactionPaid
              }
            >
              Order Searches
            </Button>
          </div>
        )}
      </Card>
      {isModalOpen && (
        <OrderSearchModal
          onClose={() => setIsModalOpen(false)}
          propertyAddress={propertyAddress}
          transactionId={transactionId}
          onSearchesOrdered={onSearchesOrdered}
        />
      )}
    </>
  );
};
