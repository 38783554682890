import { useState } from "react";
import { Formik } from "formik";
import { z } from "zod";
import { withZodSchema } from "formik-validator-zod";

import Typography from "@/foundation/Typography/Typography";
import { Address } from "../../pages/UserDetails/types";
import { Modal } from "../../../components/Modal/Modal";
import { Input } from "../../../components/Formik/Input/Input";
import { ApiController } from "../../../service/ApiController";
import { AdminTransaction } from "../../pages/TransactionDetails/types";
import ErrorText from "@/foundation/Typography/ErrorText";

interface OrderSearchModalProps {
  onClose: () => void;
  onSearchesOrdered: (transaction: AdminTransaction) => void;
  propertyAddress: Address;
  transactionId: string;
}

export const OrderSearchModal = ({
  onClose,
  onSearchesOrdered,
  propertyAddress,
  transactionId,
}: OrderSearchModalProps) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const OrderSearchFormSchema = z
    .object({
      subBuildingName: z.string().optional(),
      buildingName: z.string().optional(),
      buildingNumber: z.string().optional(),
    })
    .refine(
      (data) => {
        return !!data.buildingName || !!data.buildingNumber;
      },
      {
        message: "Either building name or number is required",
        path: ["buildingNumber"],
      },
    );

  const onOrderSearch = async (
    values: z.infer<typeof OrderSearchFormSchema>,
  ) => {
    setLoading(true);

    try {
      const updatedTransaction = await ApiController.orderSearches(
        transactionId,
        values,
      );
      onSearchesOrdered(updatedTransaction);
      onClose();
    } catch (error) {
      setError(
        error instanceof Error ? error.message : "An unkown error occured",
      );
    }

    setLoading(false);
  };

  return (
    <Formik
      initialValues={{
        subBuildingName: propertyAddress.sub_building_name || "",
        buildingName: propertyAddress.building_name || "",
        buildingNumber: propertyAddress.building_number || "",
      }}
      validate={withZodSchema(OrderSearchFormSchema)}
      onSubmit={onOrderSearch}
      validateOnMount
    >
      {({ handleSubmit }) => (
        <Modal
          title="Order Search"
          saveButtonText="Order Search"
          cancelButtonFunction={onClose}
          saveButtonFunction={handleSubmit}
          isSaveButtonDisabled={loading}
        >
          <Typography className="mb-[16px]">
            A Search will be ordered for the following address, please check the
            input details against the address and adjust any fields:
          </Typography>

          <Typography weight="bold">{propertyAddress.line_1}</Typography>
          <Typography weight="bold">{propertyAddress.line_2}</Typography>
          <Typography weight="bold">{propertyAddress.line_3}</Typography>
          <Typography weight="bold">{propertyAddress.post_town}</Typography>
          <Typography weight="bold">{propertyAddress.postcode}</Typography>

          <Typography className="my-[16px]">
            Sub-building name/number is optional, it should be filled in if it
            is a flat.
          </Typography>

          <div className="my-[20px]">
            <Input
              fieldName="subBuildingName"
              label="Sub-building name/number"
            />
            <hr className="my-[16px]" />

            <Typography className="mb-[16px]">
              One of the following fields must be present:
            </Typography>

            <Input fieldName="buildingName" label="Building name" />
            <Input fieldName="buildingNumber" label="Building number" />
          </div>

          {error && <ErrorText>{error}</ErrorText>}
        </Modal>
      )}
    </Formik>
  );
};
